.mentions {
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border-radius: '5px';
  border: 0;
}

.mentions--multiLine .mentions__control {
  /* font-family: monospace; */
  /* font-size: 14pt; */
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 215px;
  line-height: 1em;
  border: '1px solid lightgray';
}
.mentions--multiLine .mentions__input {
  /* border: 1px solid silver; */
  padding: 9px;
  outline: 0;
  border-radius: '5px';
  line-height: 1em;
  border: 0;
}

.mentions__suggestions__list {
  background-color: white;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

.mentions-reply {
}

.mentions-reply--singleLine .mentions-reply__control {
  display: inline-block;
  width: 130px;
}
.mentions-reply--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions-reply--singleLine .mentions-reply__input {
  padding: 1px;
  border-radius: '5px';
  border: 0;
}

.mentions-reply--multiLine .mentions-reply__control {
  /* font-family: monospace; */
  /* font-size: 14pt; */
  border: 2px solid #e2e2e2;
}
.mentions-reply--multiLine .mentions-reply__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 1em;
  line-height: 1em;
  border: '1px solid lightgray';
}
.mentions-reply--multiLine .mentions-reply__input {
  /* border: 1px solid silver; */
  padding: 9px;
  outline: 0;
  border-radius: '5px';
  border: 0;
}

.mentions-reply__suggestions__list {
  background-color: white;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  font-size: 10pt;
}

.mentions-reply__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions-reply__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions-reply__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

.mentions-new {
}

.mentions-new--singleLine .mentions-new__control {
  display: inline-block;
  width: 130px;
}
.mentions-new--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions-new--singleLine .mentions-new__input {
  padding: 1px;
  border-radius: '5px';
  border: 0;
}

.mentions-new--multiLine .mentions-new__control {
  /* font-family: monospace; */
  /* font-size: 14pt; */
  border: 2px solid #e2e2e2;
}
.mentions-new--multiLine .mentions-new__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 215px;
  line-height: 1em;
  border: '1px solid lightgray';
}
.mentions-new--multiLine .mentions-new__input {
  /* border: 1px solid silver; */
  padding: 9px;
  outline: 0;
  border-radius: '5px';
  border: 0;
}

.mentions-new__suggestions__list {
  background-color: white;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  font-size: 10pt;
}

.mentions-new__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions-new__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions-new__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}
