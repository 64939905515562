#tech-map {
  width: 300px;
  height: 300px;
}

.MuiListItemButton-root.Mui-selected, .MuiListItemButton-root.Mui-selected:hover {
  background-color: 'aliceblue';
}

.MuiListItemButton-root.Mui-selected .MuiTypography-root {
  font-weight: bold;
  color: #3f51b5;
}

/* Remove browser default close button on search inputs */
input[type="search"].dark {
  background: #222;
  color: #fff;
}

input[type="search"].light {
  background: #fff;
  color: #222;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.MuiTable-root .MuiTableCell-root .MuiInput-root input {
  font-size: 15px;
  padding-bottom: 0;
}
