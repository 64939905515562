.color_ready_for_pickup {
  color: green;
}

.color_picked_up {
  color: #00b500;
}

.color_not_picked_up {
  color: red;
}

.bg_quoted {
  background-color: lightgray;
}

.bg_eta_requested {
  color: black;
  background-color: orange;
}

.bg_eta_received {
  color: black;
  background-color: yellow;
}

.bg_order_requested {
  background-color: lightcoral;
}

.bg_order_placed {
  background-color: lightslategray;
}

.bg_ready_for_pickup {
  background-color: lightgreen;
}

.bg_picked_up {
  background-color: #00b500;
}

.bg_not_picked_up {
  background-color: red;
  color: white !important;
}

.bg_canceled,
.bg_removed {
  background-color: black;
  color: white !important;
}

.bg_return_initiated,
.bg_refund_initiated,
.bg_core_refund_initiated {
  background-color: darkgray;
}

.bg_returned,
.bg_refunded,
.bg_core_refunded {
  background-color: black;
  color: white !important;
}

.bg_core_returned,
.bg_part_returned {
  background-color: magenta;
}

/* csr status classes */
.bg_paid {
  background-color: green;
  color: white !important;
}

.bg_refunded {
  background-color: #fffd54;
  color: black !important;
}

.bg_pending_refund {
  background-color: #46162f;
  color: white !important;
}

.bg_pending_return {
  background-color: #eb3223;
  color: white !important;
}

.bg_lost {
  background-color: lightgray;
}

.bg_lost_special_order {
  background-color: darkgray;
}

.bg_pending_dispute {
  background-color: #eecdcd;
}

.bg_disputed {
  background-color: #cdeecd;
}

.bg_pending_deduction {
  /* background-color: '' */
}

.bg_deducted {
  /* background-color: '' */
}

.border {
  border: 2px solid;
}

.border_quoted {
  border-color: lightgray;
}

.border_eta_requested {
  border-color: orange;
}

.border_eta_received {
  border-color: yellow;
}

.border_order_requested {
  border-color: lightcoral;
}

.border_order_placed {
  border-color: lightslategray;
}

.border_ready_for_pickup {
  border-color: lightgreen;
}

.border_picked_up {
  border-color: #00b500;
}

.border_not_picked_up {
  border-color: red;
}

.border_canceled,
.border_removed {
  border-color: black;
}

.border_return_initiated {
  background-color: darkgray;
}

.border_returned {
  border-color: black;
}

.border_refunded {
  border-color: black;
}

.border_eta_missing {
  border-color: lightgray !important;
}

.border_eta_fresh {
  border-color: #23cf23 !important;
}

.border_eta_stale {
  border-color: #3D9900 !important;
}